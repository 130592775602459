import Vue from "vue";
import index from "./modules/index"
import sign from "./modules/sign"
import setting from "./modules/setting"
import management from "./modules/management"
import mainLayout from "@/components/mainLayout"
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  routes:[
    {
      name: '登录',
      path: '/',
      component: ()=>import("@/pages/login/index.vue")
    },
    {
      name: '登录跳转',
      path: '/jump',
      component: ()=>import("@/pages/login/jump.vue")
    },
    {
      name: '登录',
      path: '/admin/login/login',
      component: ()=>import("@/pages/login/login.vue")
    },
    {
      path: "/index",
      meta: {title: '广西桂之保'},
      component: mainLayout,
      children: [
        ...index,
        ...sign,
        ...setting,
        management
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  window.document.title = "【广西桂之保】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台";
  next();
})
export default router;
