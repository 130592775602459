import VueCookies from 'vue-cookies'

// setToken
export const setCookies = (key, val, cookieExpires) => {
    VueCookies.set(key, val, { expires: cookieExpires || 1 })
}

// 获取getCookies
export const getCookies = (key) => {
    return VueCookies.get(key)
}

export const removeCookies = (key) => {
    return VueCookies.remove(key)
}

export function in_array(search, array){
    for(var i in array){
        if(array[i]==search){
            return true;
        }
    }
    return false;
}

export function parseQuery() {
    const res = {};

    const query = (location.href.split("?")[1] || "")
        .trim()
        .replace(/^(\?|#|&)/, "");

    if (!query) {
        return res;
    }

    query.split("&").forEach(param => {
        const parts = param.replace(/\+/g, " ").split("=");
        const key = decodeURIComponent(parts.shift());
        const val = parts.length > 0 ? decodeURIComponent(parts.join("=")) : null;

        if (res[key] === undefined) {
            res[key] = val;
        } else if (Array.isArray(res[key])) {
            res[key].push(val);
        } else {
            res[key] = [res[key], val];
        }
    });

    return res;
}
