<template>
  <div class="layout">
    <Layout :style="'min-height:'+ innerHeight + 'px'">
      <Sider ref="side1" hide-trigger collapsible :collapsed-width="78" v-model="isCollapsed">
        <div class="logo-side">
          <div class="logo-item" style="width:200px;height:58px">
            <img src="../../assets/images/logo02.png"/>
          </div>
          <!--          <div class="company-name" v-if="!isCollapsed"><span>速速</span>云签</div>-->
        </div>
        <Menu :active-name="String($route.name)"
              ref="menu"
              theme="light"
              width="auto"
              :open-names="openNames">
          <div>
            <div v-for="(item,index) in muen" :key="index">
              <div v-if="item.children.length>0 && item.children[0].is_submenu === 1">
                <Submenu :name="item.permissions_rote" >
                  <template slot="title" style="display: flex;align-items: center">
                    <div class="l-icon"><img :src="item.icon"/></div>
                    <div v-if="!isCollapsed">{{ item.permissions_name }}</div>
                  </template>
                  <MenuItem  v-for="(child,idx) in item.children" :key="idx" :name="child.permissions_rote" :to="child.path">
                    <div>{{ child.permissions_name }}</div>
                  </MenuItem>
                </Submenu>
              </div>
              <div v-else>
                <MenuItem :name="item.permissions_rote" :to="item.path" style="display: flex;align-items: center">
                  <div class="l-icon"><img :src="item.icon"/></div>
                  <div v-if="!isCollapsed">{{ item.permissions_name }}</div>
                </MenuItem>
              </div>
            </div>
          </div>
        </Menu>
      </Sider>
      <Layout>
        <Header :style="{padding: 0}" class="layout-header-bar">
          <div @click="collapsedSider"
               style="margin-top: 20px;margin-left: 10px"
               class="l-icon else-icon">
            <img src="../../assets/imgs/5.png"/>
          </div>
          <div class="r-fixed">
            <div class="all-icon">
              <Tooltip content="返回首页" placement="bottom">
                <img src="../../assets/imgs/23.png" @click="goHome" />
              </Tooltip>
<!--              <Tooltip content="老版本切换" placement="bottom" v-if="version === '1'">-->
<!--                <img src="../../assets/imgs/28.png" @click="changeEdition" />-->
<!--              </Tooltip>-->
              <Tooltip content="消息通知" placement="bottom">
                <img src="../../assets/imgs/25.png" @click="showNotice"/>
              </Tooltip>
              <div class="red flex-c" v-if="unread_num > 0">{{unread_num}}</div>
            </div>
            <div class="user-del">
              <div class="user-img flex-c">{{ userInfo.real_name?userInfo.real_name.substring(0,1):'' }}</div>
              <div class="user-name">{{ userInfo.real_name }}</div>
              <div class="sign-out">
                <div class="out flex-c" @click="showModal = true">修改密码</div>
                <div class="out flex-c" @click="logout">退出登录</div>
              </div>
            </div>
          </div>
        </Header>
        <Content :style="{minHeight: '260px'}">
          <div style="height: 90vh;overflow: auto;padding: 20px">
            <router-view v-if="reload"></router-view>
          </div>
          <div style="text-align: center;margin-bottom:5px">
            <a href="https://beian.miit.gov.cn" target="_blank" style="color:#000"><img src="@/assets/images/ba.png" alt="">&nbsp; 桂ICP备2022006976号-1</a>
          </div>
        </Content>
      </Layout>
      <div class="notice-pop" v-if="tryTrue">
        <div class="pop"></div>
        <div class="notice-table fixed">
          <div class="close-it" @click="tryTrue = false"><Icon type="md-close" size="25" /></div>
          <Table :columns="columns"  @on-row-click="clickNotice" :row-class-name="rowClassName" :data="data"></Table>
          <Page
                  :total="tables.total"
                  :current="tables.current"
                  @on-change="changeTables"
                  :page-size="tables.limit"
                  style="text-align: right;margin-top: 16px"
                  show-elevator show-total />
        </div>
      </div>
      <!-- 公告详情-->
      <Modal v-model="noticeForm.contentModel" :mask-closable="false" footer-hide  :title="noticeForm.contentTitle">
        <div style="padding-top:10px;min-height:200px">{{noticeForm.contentNotice}}</div>
      </Modal>
      <!-- 修改密码-->
      <modal v-if="showModal" :modalInfo="modalInfo" @giveVal="getVal" @closeModal="showModal=false"></modal>
    </Layout>
  </div>
</template>
<script>
  import {
    AccountLogout,
    announceList,
    changePassword,
    readAnnounce,
    switchEdition
  } from '../../api/interFace'
  import {getCookies,removeCookies} from '@/utils/helper'
  import modal from '../../components/modal/index'
  export default {
    data () {
      return {
        isCollapsed: false,
        showModal: false,
        innerHeight: 0,
        openNames: [],
        reload: true,
        columns: [
          {
            title: '标题',
            key: 'title'
          },
          {
            title: '类型',
            key: 'notice_type',
            render: (h, params) => {
              let auth_text = "普通公告",
                      auth_color= "#515a6e";
              if (params.row.notice_type == 1) {
                auth_text = "紧急公告",
                        auth_color= "#FF0000";
              } else if (params.row.notice_type == 2) {
                auth_text = "重要公告",
                        auth_color= "#FF0000";
              }
              return h('div', [
                h('span', {
                  style: {color: auth_color},
                  props: {
                    type: 'text',
                    size: 'small'
                  }
                }, auth_text)
              ]);
            }
          },
          {
            title: '时间',
            key: 'send_time'
          }
        ],
        data: [],
        tables:{
          page:1,
          total:0,
          limit:5,
        },
        tryTrue:false,
        unread_num:'',
        noticeForm:{
          contentModel:false,
          contentTitle:"",
          contentNotice:""
        },
        modalInfo: {
          title: '修改密码',
          inputList: [
            {
              label: '新密码',
              inputType: 'password',
              inputVal: ''
            },
            {
              label: '确认新密码',
              inputType: 'password',
              inputVal: ''
            },
          ]
        },
        seal:{
          password:'',
          new_password:'',
          true_password:'',
        },
        version:'',
      }
    },
    components:{modal},
    mounted() {
      this.innerHeight = window.innerHeight;
      this.selectMenu();
      let arr = []
      this.muen.forEach(item=>{
        if(item.permissions_rote === 'settingIndex'){
          arr=item.children
        }
      })
      this.$store.commit('setSetting', arr)
    },
    watch: {
      '$route.path'() {
        this.selectMenu();
      }
    },
    computed: {
      muen(){
        return this.$store.state.menuSecond
      },
      userInfo(){
        return this.$store.state.userInfo
      },
    },
    created() {
      this.version = getCookies('version')
      this.getAnnounceList()
    },
    methods: {
      getAnnounceList(){
        announceList(this.tables).then(res=>{
          this.data = res.data.data || []
          this.tables.total = res.data.total;
          this.unread_num = res.data.unread_num;
        })
      },
      getVal(e) {
        this.seal.new_password = e[0]
        this.seal.true_password = e[1]
        changePassword(this.seal).then(async res => {
          this.$Message.success(res.msg)
          this.showModal =false
          setTimeout(()=>{
            this.$router.replace('/')
          },500)
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      },
      changeEdition(){
        switchEdition({type:0}).then(res=>{
          // window.open(res.data.url)
          window.location.href=res.data.url
        }).catch(res=>{
          console.log(res)
        })
      },
      showNotice(){
        this.tryTrue = true
      },
      clickNotice(v){
        let that = this;
        this.noticeForm.contentModel = true;
        this.noticeForm.contentTitle = v.title;
        this.noticeForm.contentNotice = v.content;
        this.data.forEach(function(item){
          if(item.id == v.id && item.status == 0) {
            item.status = 1;
            that.tables.num--;
          }
        })
        readAnnounce({id: v.id}).then(res=>{
          this.getAnnounceList();
          console.log(res)
        }).catch({})
      },
      changeTables(v){
        this.tables.page = v;
        this.getAnnounceList();
      },
      collapsedSider () {
        this.$refs.side1.toggleCollapse();
      },
      goHome(){
        this.$router.push({ path: '/index' })
      },
      handleReload () {
        this.reload = false
        setTimeout(()=>{
          this.reload = true
        },200)
      },
      rowClassName(row){
        if(row.status == 0) {
          return  "demo-table-error-row  table-cursor-row";
        }
        return "table-cursor-row"
      },
      logout () {
        let that = this
        this.$Modal.confirm({
          title: '退出登录确认',
          content: '您确定退出当前账户吗？',
          onOk () {
            AccountLogout({
              id: getCookies('uuid')
            }).then(res => {
              console.log(res)
              // that.$store.commit('userInfo/type', null)
              that.$Message.success('您已成功退出')
              if (that.path) {
                window.location.href = that.path
              } else {
                that.$router.replace('/')
              }
              localStorage.clear()
              removeCookies('token')
              removeCookies('expires_time')
              removeCookies('uuid')
            }).catch(res => {
              console.log(res)

            })
          }
        })
      },
      /**修改导航展开样式 */
      selectMenu() {
        this.openNames = [this.$route.name.split('-')[0]];
        this.$store.commit('setType', '')
        this.$nextTick(() => {
          this.$refs.menu.updateOpened();
        })
      },
    }
  }
</script>
<style scoped>
  .layout{
    position: relative;
    overflow: hidden;
  }
  .ivu-layout {
    background: #EDF0F9;
  }
  .layout-header-bar{
    background: #fff;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    position: relative;
  }
  .layout-logo-left{
    width: 90%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 15px auto;
  }
  .menu-icon{
    transition: all .3s;
  }
  .rotate-icon{
    transform: rotate(-90deg);
  }
  .menu-item span{
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
  }
  .menu-item i{
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
  }
  .collapsed-menu span{
    width: 0px;
    transition: width .2s ease;
  }
  .collapsed-menu i{
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 22px;
  }
  .logo-item {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  .logo-item img {
    width: 100%;
    height: 100%;
  }
  .logo-side {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    box-sizing: border-box;
  }
  .company-name {
    font-size: 22px;
    font-weight: bold;
    color: #545F78;
  }
  .company-name span {
    color: #4877E8;
  }
  .r-fixed {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
  .all-icon {
    display: flex;
    align-items: center;
    position: relative;
  }
  .red {
    position: absolute;
    top: -15px;
    right: 5px;
    width: 20px;
    height: 20px;
    background: #FD5454;
    color: white;
    font-size: 12px;
    border-radius: 50%;
  }
  .all-icon img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    display: inline-block;
    cursor: pointer;
  }
  .user-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #1a9aef;
    margin-right: 10px;
    margin-left: 20px;
    color: white;
    font-size: 16px;
  }
  .user-img img {
    width: 100%;
    height: 100%;
  }
  .user-del {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 64px;
  }
  .user-del:hover .sign-out {
    display: block;
  }
  .sign-out {
    width: 120px;
    height: 70px;
    padding-top: 5px;
    background: #fff;
    position: absolute;
    left: 15px;
    bottom: -70px;
    z-index: 9999999999999999999;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    display: none;
  }
  .sign-out .out {
    height: 30px;
    text-align: center;
    display: block;
    line-height: 30px;
  }

  .sign-out .out:hover {
    color: #fff;
    background: #DBDEE6;
  }
  .user-name {
    font-size: 15px;
    color: #545F78;
  }
  .notice-table {
    width: 600px;
    height: 400px;
    background: white;
    border-radius: 8px;
    padding:40px 20px 20px 20px;
    box-sizing: border-box;
  }
  .close-it {
    position: absolute;
    top: 8px;
    right: 12px;
    cursor: pointer;
  }
</style>
<style>
  ::-webkit-scrollbar { display: none; }
  .ivu-layout-sider {
    background: white!important;
  }
  .l-icon {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    cursor: pointer;
  }
  .l-icon img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  .ivu-menu-submenu-title {
    display: flex;
  }
  .layout-header-bar {
    border-left: 2px solid #EDF0F9;
  }
  .ivu-layout-header {
    line-height: unset!important;
  }
  .ivu-table .demo-table-error-row td{
    color: #2c8cf0;
  }
  .ivu-table .table-cursor-row td{
    cursor: pointer;
  }
</style>
