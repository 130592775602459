<template>
  <div class="modal-wrap">
    <div class="pop"></div>
    <div class="pop-modal fixed">
      <div class="modal-title">{{ modalInfo.title }}</div>
      <div class="input-wrap">
        <div class="input-nav flex" v-for="(item,index) in modalInfo.inputList" :key="index">
          <div class="label">{{ item.label }}</div>
          <div class="val">
            <Input v-model="item.inputVal" :type="item.inputType" :placeholder="'请输入' + item.label"/>
          </div>
        </div>
      </div>
      <div class="give">
        <div class="cancel flex-c" @click="closeModal">取 消</div>
        <div class="require flex-c" @click="giveVal">确 定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props:{
    modalInfo:{
      type:Object
    }
  },
  data(){
    return{
      contract_name:''
    }
  },
  methods:{
    giveVal(){
      let arr = this.modalInfo.inputList || []
      let giveVal = []
      arr.forEach(item=>{
        giveVal.push(item.inputVal)
      })
      this.$emit('giveVal',giveVal)
    },
    closeModal(){
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">
.modal-wrap {
  .pop-modal {
    width: 500px;
    background: #FFFFFF;
    box-shadow: 0px 2px 9px 0px rgba(72, 119, 232, 0.39);
    border-radius: 6px;
    padding: 24px 34px;
    box-sizing: border-box;
    z-index: 999;
    .modal-title {
      font-size: 18px;
      font-weight: bold;
      color: #191A1F;
      margin-bottom: 25px;
    }
    .input-nav {
      margin-bottom: 25px;
      .label {
        font-size: 14px;
        font-weight: bold;
        color: #545F78;
        width: 80px;
        text-align: center;
      }
      .val {
        flex: 1;
      }
    }
    .give {
      display: flex;
      justify-content: right;
    }
    .require {
      width: 100px;
      height: 34px;
      background: #4877E8;
      border: 1px solid #4877E8;
      border-radius: 6px;
      font-size: 16px;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .cancel {
      width: 100px;
      height: 34px;
      color: #1B2742;
      border-radius: 6px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      margin-right: 20px;
      &:hover {
       color: #4877E8;
      }
    }
  }
}

</style>
