export default{
    title: '电子合同后台',
    // apiUrl:'http://192.168.1.201:84',
    // apiUrl:'http://susuyunqian.hulianzaowu.com/',
    elseUrl:'http://guanwang.puhuijishu.xyz',
    appletsBaseURL:'https://wxapplets.puhuijishu.top/api',
    // apiUrl:'http://guanwang.puhuijishu.xyz',
    // apiUrl:'https://www.guizhibao.cn'
    apiUrl : `${location.origin}`
}

